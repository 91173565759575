<template>
  <div id="write-email" v-cloak v-loading.fullscreen.lock="loading">
    <el-row class="email-row-0">
      <el-col :span="2" class="email-tit">
        <span> {{ $t("emailList.to") }}: </span>
      </el-col>
      <el-col :span="14">
        <el-select
          v-model="sendData.to_email"
          class="send-w"
          size="mini"
          multiple
          filterable
          allow-create
          default-first-option
          :disabled="returnType == 'huiFu'"
        >
          <el-option
            v-for="item in contacts"
            :key="item.id"
            :label="
              item.email +
              ' ' +
              (Object.prototype.hasOwnProperty.call(item, 'consignee')
                ? item.consignee
                : item.desc)
            "
            :value="item.email"
          ></el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-row class="email-row-0">
      <el-col :span="2" class="email-tit">
        <span> {{ $t("emailList.cc") }}: </span>
      </el-col>
      <el-col :span="14">
        <el-select
          v-model="sendData.cc_email"
          class="send-w"
          size="mini"
          multiple
          filterable
          allow-create
          default-first-option
        >
          <el-option
            v-for="item in contacts"
            :key="item.id"
            :label="
              item.email +
              ' ' +
              (item.hasOwnProperty('consignee') ? item.consignee : item.desc)
            "
            :value="item.email"
          ></el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-row class="email-row-0">
      <el-col :span="2" class="email-tit">
        <span> {{ $t("emailList.subject") }}: </span>
      </el-col>
      <el-col :span="14">
        <el-input
          v-model="sendData.subject"
          class="send-w"
          size="mini"
        ></el-input>
      </el-col>
    </el-row>
    <el-row class="email-row-0">
      <el-col :span="8">
        <el-button @click="putSign" size="mini">{{
          $t("writeEmail.includeSign")
        }}</el-button>
        <el-button @click="clearContent" size="mini" type="danger">{{
          $t("writeEmail.clearAll")
        }}</el-button>
        <el-button @click="sendEmail" size="mini" type="primary">{{
          $t("writeEmail.send")
        }}</el-button>
      </el-col>
    </el-row>
    <el-row class="row-editor">
      <el-col :span="24" class="col-editor">
        <div id="editor"></div>
      </el-col>
    </el-row>

    <el-row class="email-attachments">
      <el-col :span="4">
        <el-upload
          :accept="uploadAccept"
          class="upload-box"
          :limit="5"
          :data="uploadParam"
          action="/home/Plugs/emailUpload"
          ref="upload"
          :auto-upload="false"
          :file-list="sendData.attachments"
          :on-change="uploadOnchange"
          :on-success="successUpload"
          :multiple="true"
          :on-exceed="exceed"
          :on-remove="RemoveFile"
        >
          <el-button slot="trigger" type="primary" class="search_button">{{
            $t("taskInfo.attachment")
          }}</el-button>
          <el-button type="success" class="search_button" @click="upload">{{
            $t("emailList.startUpload")
          }}</el-button>
        </el-upload>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import E from "wangeditor";
import { toMessage } from "@/utils/message";
export default {
  name: "WriteEmail",
  data() {
    return {
      loading: false,
      sendData: {
        subject: "",
        content: "",
        to_email: [],
        cc_email: [],
        attachments: [],
      },
      contacts: [],
      uploadAccept:
        ".xlsx, .xls, .pdf, .png, .jpg, .doc, .docx, .zip, .rar, .jpeg", //上传限制文件
      size: 5 * 1024,
      uploadParam: { str: new Date().getTime() },
      is_sign: true,
      sign_info: "",
      returnType: "",
      file_atta: {},
      weStyle: `
			<style>
			table {
		border-collapse:collapse;
	}
	table, td, th {
		border:1px solid #EBEEF5; color: #606266; font-size:14px
	}
	td, th {
		padding: 5px;
		text-align: center;
	}
	th{
		padding: 10px; background-color: #f5f7fa; color:#909399
	}
	.tit-h{
		font-size: 21px; margin: 20px 0; text-align: center; color: #606266;
	}
	.tit{
		font-size: 14px; text-align: right; color: #606266; display: inline-block;
	}
	.info-box{
		margin-bottom: 20px;
	}
	.font-color{
		color:#606266
	}
	.ml30 {
		margin-left: 30px;
	}
	.all-info-box{
		display:flex; margin-top: 20px;
	}
	.all-info-box .font-color{
		margin-bottom: 20px;
	}
	.add-out,.desc-out{
		display: flex;
	}
	.add-inner{
		width: 300px
	}
	.sum-box{
		background-color: #F5F7FA; color: #606266; height: 30px; line-height: 30px; padding-left: 10px;
	}
	.desc-inner{
		width: 260px;
	}
	.content {
		width: 980px;
		margin: 0 auto;
	}
	.all-info-box+.font-color{
		margin-bottom: 10px;
	}
	.mb10{
		margin-bottom:10px
	}
	.tit-h{
		font-size: 21px;
		margin: 20px 0;
		text-align: center;
		color: #606266;
	}
	.ellipsis {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	.w150{
		width: 150px;
	}
	</style>
	`,
    };
  },
  methods: {
    // 删除文件
    RemoveFile(val) {
      for (let i = 0; i < this.sendData.attachments.length; i++) {
        if (val.uid == this.sendData.attachments[i].uid) {
          this.sendData.attachments.splice(i, 1);
        }
      }
      let name = val.name + "@";
      if (Object.prototype.hasOwnProperty.call(this.file_atta, name)) {
        delete this.file_atta[name];
      }
    },
    //上传附件
    upload() {
      this.$refs.upload.submit();
    },
    //上传框 状态发生改变时
    uploadOnchange(file, fileList) {
      // 判断图片是否过大
      if (file.size > this.size * 1024) {
        this.$message(toMessage("warning", this.$t("writeEmail.warning")));
      }
      // 判断图片是否符合格式
      let lastName = "." + file.name.replace(/.+\./, "").toLowerCase();
      if (this.uploadAccept.indexOf(lastName) < 0) {
        this.$message(
          toMessage("warning", file.name + this.$t("writeEmail.warning1"))
        );
      }

      let newFileList = [];
      for (let i = 0; i < fileList.length; i++) {
        let have = true;
        // 判断是否重复
        for (let j = 0; j < newFileList.length; j++) {
          if (fileList[i].name == newFileList[j].name) {
            have = false;
            break;
          }
        }
        // 判断图片是否过大
        if (fileList[i].size > this.size * 1024) {
          have = false;
        }
        // 判断图片是否符合格式
        if (
          this.uploadAccept.indexOf(
            "." + fileList[i].name.replace(/.+\./, "").toLowerCase()
          ) < 0
        ) {
          have = false;
        }
        if (have) {
          newFileList.push(fileList[i]);
        }
      }
      this.sendData.attachments = newFileList;
    },
    //文件上传超限制
    exceed() {
      this.$message(toMessage("warning", this.$t("writeEmail.warning2")));
    },
    //上传成功回调
    successUpload(res, file, fileList) {
      if (res.status != 1) {
        file.status = "ready";
        this.$message(
          toMessage("error", file.name + this.$t("writeEmail.error"))
        );
        for (let i = 0; i < fileList.length; i++) {
          if (file.uid == fileList[i].uid) {
            fileList.splice(i, 1);
            break;
          }
        }
        this.sendData.attachments = fileList;
        return;
      }
      this.file_atta[res.data.name] = res.data.path;
    },
    addTab: function (
      option = "Index",
      action = "welCome",
      name = "首页",
      param
    ) {
      let url = "/" + option + "/" + action;
      if (param) {
        for (var i in param) {
          url += "/" + param[i];
        }
      }
      console.log(name);
      this.$router.push(url);
    },
    //发送邮件
    sendEmail() {
      let newto_email = [];
      this.sendData.to_email.forEach((item) => {
        if (item.trim().length != 0) {
          newto_email.push(item);
        }
      });
      let newcc_email = [];
      this.sendData.cc_email.forEach((item) => {
        if (item.trim().length != 0) {
          newcc_email.push(item);
        }
      });
      //验证收件人邮箱和主题是否填写
      let subject = this.sendData.subject.trim();
      if (newto_email.length == 0) {
        this.$message(toMessage("warning", this.$t("writeEmail.warning3")));
        return;
      }
      if (subject.length == 0) {
        this.$message(toMessage("warning", this.$t("emailList.warning3")));
        return;
      }
      //验证所有附件是否已经上传
      for (let i in this.sendData.attachments) {
        if (this.sendData.attachments[i].status != "success") {
          this.$message(toMessage("warning", this.$t("emailList.warning4")));
          return;
        }
      }
      this.loading = true;
      this.axios
        .post("/home/Plugs/sendEmail", {
          toEmail: newto_email,
          subject: subject,
          html: this.weStyle + this.sendData.html,
          ccEmail: newcc_email,
          str: this.uploadParam.str,
          file_atta: this.file_atta,
        })
        .then((res) => res.data)
        .then((res) => {
          this.loading = false;
          if (res.status !== 1) {
            this.$message(toMessage("error", res.msg));
            return;
          }
          //延迟120秒同步远端邮件
          this.$parent.cacheEmail(120000);
          //跳转
          this.addTab("CrmEmail", "sendBox", "");
        });
    },
    //写信，加载联系人
    getContacts() {
      //获取联系人列表
      this.axios
        .post("/home/Plugs/getContacts")
        .then((res) => res.data)
        .then((res) => {
          this.loading = false;
          if (res.status !== 1) {
            this.$message(toMessage("error", res.msg));
            return;
          }
          this.contacts = res.data;
        });
    },
    /**
     * 富文本编辑器基本操作===========start
     */
    //携带签名
    putSign() {
      if (this.is_sign) {
        let html = this.editor.txt.html();
        this.setContent(html + this.sign_info);
      } else {
        let html = this.editor.txt.html();
        let now_html = html;
        if (html.indexOf('<div class="signInfoHtml"></div>') >= 0) {
          let html_split = html.split('<div class="signInfoHtml"></div>');
          now_html = html_split[0] + html_split[2];
        }
        this.setContent(now_html);
      }
      this.is_sign = !this.is_sign;
    },
    //清空内容
    clearContent() {
      this.is_sign = true;
      this.setContent("");
    },
    //跳出本div
    jumpDiv() {
      this.editor.txt.append("<p><br></p>");
      this.editor.change();
    },
    //添加富文本内容
    addContent(text) {
      this.editor.cmd.do("insertHTML", text);
      this.editor.txt.append("<p><br></p>");
      this.editor.change();
    },
    //富文本内容赋值
    setContent(text) {
      this.editor.txt.html(text);
      // this.editor.change();
    },
    //打开邮件，邮件详情
    setEmailInfo(id) {
      this.axios
        .post("/home/CrmEmail/getEmailInfo", {
          id: id,
        })
        .then((res) => res.data)
        .then((res) => {
          this.loading = false;
          if (res.status != 1) {
            this.$message(toMessage("error", res.msg));
            return;
          }
          let sendBody = "";
          let recBody = "";
          if (res.data.is_sent == 1) {
            res.data.to_email = Object.keys(JSON.parse(res.data.to_email));
            sendBody = res.data.selfEmail;
            recBody = res.data.to_email.join(" , ");
          } else {
            sendBody = res.data.to_email;
            recBody = res.data.selfEmail;
            res.data.to_email = [res.data.to_email];
          }
          //拼凑头部
          let header =
            '<div style="height: 100px; padding: 10px;">&nbsp;</div>';
          header +=
            '<div style="margin:20px 0;font-size: 15px;color: #606266;">-------------' +
            this.$t("emailList.originalEmail") +
            "---------------</div>";
          header += '<div style="background: #f5f5f5">';
          header += `<div style="margin:2px;color: #606266;padding: 5px;">${this.$t(
            "emailList.from"
          )}: ${sendBody}</div>`;
          header += `<div style="margin:2px;color: #606266;padding: 5px;">${this.$t(
            "emailList.subject"
          )}：${res.data.subject}</div>`;
          header += `<div style="margin:2px;color: #606266;padding: 5px;">${this.$t(
            "emailList.time"
          )}：${res.data.date}</div>`;
          header += `<div style="margin:2px;color: #606266;padding: 5px;">${this.$t(
            "emailList.to"
          )}：${recBody}</div>`;
          header += `</div><div>&nbsp;</div>`;
          res.data.content = header + res.data.content + "<div>&nbsp;</div>";
          res.data.subject =
            (this.returnType == "zhuanFa"
              ? this.$t("emailList.forward") + "："
              : this.$t("emailList.reply") + "：") + res.data.subject;
          res.data.cc_email = [];

          //给sendData赋值
          res.data.attachments = [];
          this.$set(this, "sendData", res.data);
          this.setContent(res.data.content);

          // if(this.returnType == 'zhuanFa' && res.data.atta_name) {
          //     let atta_name = JSON.parse(res.data.atta_name);
          //     this.file_atta = atta_name;
          //
          //     this.sendData.attachments = [];
          //     for( let key in atta_name) {
          //         this.sendData.attachments.push(
          //             {name: key.split('@')[0]}
          //         );
          //     }
          // }
        })
        .catch(() => {
          this.loading = false;
          toMessage("error", this.$t("error"));
          return;
        });
    },
    makeSignInfo() {
      let sign_html = `<div style="display: flex;align-items: center; color: #8c8c90">
                                    <span >${this.$t("email.sign")}：</span>
                                    <div>${this.sign_info}</div>
                                </div>`;
      this.sign_info =
        '<div class="signInfoHtml"></div><div style="height: 1px;"></div>' +
        sign_html +
        '<div class="signInfoHtml"></div>';
    },
    /**
     * 富文本编辑器基本操作===========end
     */
    getSignInfo() {
      this.loading = true;
      this.axios
        .post("/home/Index/getEmailInfo")
        .then((res) => res.data)
        .then((res) => {
          this.loading = false;
          if (res.status != 1) {
            this.$message(toMessage("error", res.msg));
            return;
          }
          this.sign_info = res.data.sign_desc;
          this.makeSignInfo();
        });
    },
  },
  created: function () {
    let param = this.$route.params;
    if (param.returnType && param.emailInfoId) {
      this.returnType = param.returnType;
      this.setEmailInfo(param.emailInfoId);
    }
    this.getSignInfo();
    this.getContacts();
  },
  mounted: function () {
    this.editor = new E("#editor");
    // 或者 var editor = new E( document.getElementById('editor') )
    this.editor.config.onchange = (html) => {
      this.sendData.html = html;
    };
    this.editor.config.height = 400;
    this.editor.config.zIndex = 99;
    this.editor.config.menus = [
      "bold", // 粗体
      "fontSize", // 字号
      "fontName", // 字体
      "italic", // 斜体
      "underline", // 下划线
      "strikeThrough", // 删除线
      "foreColor", // 文字颜色
      "backColor", // 背景颜色
      "link", // 插入链接
      "list", // 列表
      "justify", // 对齐方式
      "quote", // 引用
      "image", // 插入图片
      "table", // 表格
      "undo", // 撤销
      "redo", // 重复
    ];
    // 自定义字体
    this.editor.config.fontNames = ["宋体", "微软雅黑"];
    // 多语言
    this.editor.config.lang = {
      设置标题: "title",
      正文: "p",
      链接文字: "link text",
      上传图片: "upload image",
      上传: "upload",
      创建: "init",
      字体: "fonts",
      文字颜色: "text color",
      背景色: "background color",
      设置列表: "settings list",
      对齐方式: "alignment",
      网络图片: "web picture",
      图片链接: "image link",
      链接: "link",
      插入表格: "insert table",
      插入: "insert",
      字号: "font size",
      有序列表: "ordered list",
      无序列表: "unordered list",
      行: "line",
      列: "column",
      靠左: "  left",
      居中: "center",
      靠右: " right",
      的表格: " ",
    };
    this.editor.create();
    this.editor.txt.html("");
  },
};
</script>

<style scoped>
#write-email {
  padding: 28px !important;
  height: 100%;
  overflow: auto;
}
.upload-box >>> .el-button {
  margin-right: 5px;
}
.email-row-0 {
  font-size: 14px;
  height: 50px;
  color: #6e6e6f;
}
.send-w {
  width: 90%;
}
.email-attachments {
  margin-top: 24px;
}
/* .col-editor,
#editor {
  height: 100%;
} */
.email-tit {
  padding-right: 10px;
  text-align: right;
  line-height: 25px;
}
/* .row-editor {
  height: 50%;
} */
.w-e-text-container {
  z-index: 100 !important;
  height: 100% !important;
}
.w-e-menu {
  z-index: 200 !important;
}
.w-e-text-container {
  z-index: 100 !important;
}
</style>